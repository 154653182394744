<template>
    <div>
      <header-portrate />
      <exports-print ref="exportbar" />
      
    <div>
      <bread-crumbs :items='items' />
      <tabsComp :tabs="tabs" />
      
      <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
        <v-row class="mt-10">
          <v-col
            cols="12"
            md="10"
            sm="12">
            <form autocomplete="off">
              <v-row>
                <v-col cols="12" md="2" sm="12">
                  <label>{{lang.start_date}}</label>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                  </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <label>{{lang.end_date}}</label>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                  </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <label>{{lang.mobile}}</label>
                  <b-input-group>
                    <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
                  </b-input-group>
                </v-col>
                
                <v-col cols="12" md="2" sm="12">
                  <label></label>
                  <b-input-group style="margin-top:-5px">
                    <b-button variant="light" style="background:blue;color:#fff;width:75px;">{{lang.search}}</b-button>
                  </b-input-group>
                </v-col>
              </v-row>
          </form>
          </v-col>
          <!-- <v-col cols="12" md="4" sm="12" class="text-end" v-if="!this.$store.state.licenseType.isBill"> -->
          <v-col cols="12" md="2" sm="12" class="text-end pt-7">
            <b-button
                @click="addPerMaint()"
                v-b-toggle.add_periodic_maint
                class="btn-sm btn"
                style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
                id="multiply-button" 
              >
              <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add}}</div>
              
              </b-button>
              
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="12"
            style="display:none;"
            >
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="lang.search"
                  single-line
                  hide-details
              ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
            <v-data-table
                :headers="headers"
                :items="tablerows"
                :search="search"
                :items-per-page="15"
                width="100%"
                id="MainTable"
                hide-default-footer
                fixed-header
            >
              <template v-slot:item="row">
                <tr>
                  <td style="width:50px;text-align:center;">{{ row.item.date }}</td>
                  <td :style="`border:2px solid #fff !important;width:50px;text-align:center;` + row.item._customer_type_style">{{ row.item._customer_type }}</td>
                  <td class="text-center" nowrap v-if="row.item.customer_type == 1">{{ row.item.full_name }}</td>
                  <td class="text-center" nowrap v-if="row.item.customer_type == 2">{{ row.item.company_name }}</td>
                  <td class="text-center" nowrap v-if="row.item.customer_type == 3">{{ row.item.entity_name }}</td>
                  <td class="text-center">{{ row.item.mobile }}</td>
                  <td class="text-center">{{ row.item.technical }}</td>
                  <td class="text-center">{{ row.item.type }}</td>
                  <td :style='`border-left:1px solid #fff !important;width:100px;border:2px solid #FFF !important;text-align:center;color:#FFF !important;background:`+row.item._statusbgColor'>{{ row.item._status }}</td>
                  <td style="text-align:center;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                    <div class="viewBTN" >
                    {{lang.view}}</div>
                  </td>
                </tr>
              </template>
              
            </v-data-table>
          </v-col>
          <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
            <v-container>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  value="15"
                  :length="pageCount"
                  @input="getNext(page)"
                  color="#000"
                ></v-pagination>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-row>
      <div class="loadingPage" v-if="$store.state.showLoading">
        <v-progress-circular
            :width="3"
            color="green"
            indeterminate
        ></v-progress-circular>
          <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
        </div>
      <vue-snotify></vue-snotify>
    </div>
    
    <Footer />
    </div>
  </template>
  
  <script>
  // import axios from 'axios'
  // import {SnotifyPosition} from 'vue-snotify';
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  import HeaderPortrate from '@/components/Header-Portrate.vue';
  import Footer from '@/components/Footer.vue';
  import tabsComp from '@/components/tabsComp.vue'
  import ExportsPrint from '@/components/exports-print.vue';
  
    export default{
      name: 'Invoicelist',
      components: {
          BreadCrumbs,
          Footer,
          HeaderPortrate,
          tabsComp,
          ExportsPrint,
      },
      data() {
        return {
          allres: 0,
          cpage: 0,
          page: 1,
          pageCount:0,
          edinvoice: 0,
          mobile: '',
          excel_fields:{},
          tablerows: [
              {
                  date: '2024-02-20', 
                  _customer_type_style: 'background:blue;color:white !important;',
                  _customer_type: 'شركة',
                  full_name: '',
                  company_name: 'سام المتطورة',
                  entity_name: '',
                  customer_type: 2,
                  mobile: '0566566160',
                  technical: 'محمد الاحمد',
                  type: 'ميكانيكا',
                  _statusbgColor: 'blue !important',
                  _status: 'تحت الاجراء',
              }
          ],
          sd: {
            day: (new Date()).getDate(),
            month: (new Date()).getMonth() + 1,
            year: (new Date()).getFullYear(),
          },
          ed: {
            day: (new Date()).getDate(),
            month: (new Date()).getMonth() + 1,
            year: (new Date()).getFullYear(),
          },
        }
      },
      computed: {
        cpages: function(){
          // alert(parseFloat(this.page) * 15);
          // alert(this.pageCount);
          if(parseFloat(this.page) * 15 >= this.allres){
            return true
          }
          return false;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        items:function() {
            return {
              text: this.lang.invoices,
              disabled: true,
              href: '/invoicelist/',
            }
        },
        headers: function () {
          let x =  [
              { 
                text: "#", align: 'center',
                filterable: false, value: 'date',
                sortable: false,class: 'backBlack'
              },
              { 
                text: this.lang.customer_type, align: 'center',
                filterable: false, value: 'customer_type',
                sortable: false,class: 'backBlack'
              },
              {
                text: this.lang.customer, align: 'center',
                filterable: false, value: 'full_name',
                sortable: false,class: 'backBlack'
              },
              {
                text: this.lang.mobile, align: 'center',
                filterable: true, value: 'mobile',
                sortable: false, class: 'backBlack'
              },
          ];
            x.push({
              text: this.lang.technical, align: 'center',
              filterable: false, value: 'technical',
              sortable: false,class: 'backBlack'
            })
            x.push({
                text: this.lang.type, align: 'center',
                filterable: false, value: 'type',
                sortable: false,class: 'backBlack'
              })
              x.push({
                text: this.lang.status, align: 'center',
                filterable: false, value: 'status',
                sortable: false,class: 'backBlack'
              })
          x.push({
              text: this.lang.action, align: 'center',
              filterable: false, value: '',
              sortable: false,class: 'backBlack'
            });
          return x;
        },
        tabs: function(){
          // console.log(this.$store.getters['state'].licenseType.activemenu);
          const menu = this.$store.getters['state'].menu;
              const license = this.$store.getters['state'].licenseType.activemenu;
              const alicense = this.$store.getters['state'].licenseType
              let currentMenu = menu[license];
              
              if(!license){
                return false;
              }
              let cMenu = {main: {},sub:[]};
              Object.values(currentMenu).forEach(value =>{
                const cM = localStorage.getItem('currentMenu');
                // console.log("cM",cM);
                if(value.main.index == cM){
                    cMenu = value.sub;
                }else if(cM == null && value.main.index == 1){
                    cMenu = value.sub;
                }
              });
             
              let t = []
              // console.log("cMenu",cMenu);
              Object.values(cMenu).forEach(value =>{
              
                  if(this.$route.path == value.href){
                      value.class = "mytab";
                  }else{
                      value.class = "mytab4";
                  }
                  if(this.lang.langname == 'ar'){
                      value.name = value.arname
                  }else{
                      value.name = value.enname
                  }
                  
                  for(let i =0; i< value.groupid.length;i++){
                      if(value.groupid[i] == this.$store.getters['state'].group_id){
                        if(alicense[value.perms[0]] || value.perms[0] == 'any')
                            t.push(value)
                      }
                  }
              })
          
              return t;
        }
      },
      created() {
       //
      },
      methods: {
        addPerMaint(){
          //
        },
        getStartDate(){
          let d = (new Date()).getDate();
          let m = (new Date()).getMonth() + 1;
          let y = (new Date()).getFullYear();
          if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
              m = 12
              y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
              m = parseInt(m) - 1;
            }
          }else if(parseInt(d) == 30){
            d = 1;
          }else{
            d = parseInt(d) - parseInt(30);
          }
          //-- start date today
          // this.sd.day = (new Date()).getDate();
          // this.sd.month = (new Date()).getMonth() + 1;
          this.sd.day = 1;
          this.sd.month = 1;
          this.sd.year = y;
        },
        preparedata(){
          let exdata = {
              list: "invoiceList",
              title: this.lang.invoices,
              data: [],
          }
          for(let i=0;i<this.tablerowsF.length;i++){
              exdata.data.push(this.tablerowsF[i]);
          }
          // // console.log(exdata);
          this.$refs.exportbar.exportdata = exdata;
          this.$refs.exportbar.sdate = this.sdate;
          this.$refs.exportbar.edate = this.edate;
          
          this.$refs.exportbar.exporttitle = "";
          this.$refs.exportbar.palte_number = '';
          this.$refs.exportbar.contact_number = '';
        },
      }
    }
  </script>
  <style>
  .backBlue{
    background: blue !important;
    color: #fff !important;
    border-bottom: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
  }
  .backGold{
    background: gold !important;
    color: #000 !important;
    border-bottom: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
  }
  .viewBTN{
    font-size:.8rem;
    background:blue !important;
    width:100%;
    color:#fff !important;
    height:25px !important;
    margin-left:5px;
    text-align:center;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
    background:blue !important;
    width:100%;color:#fff !important;
  }
  .loadingPage{
    position: fixed;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#ffffff00;
    z-index: 100000;
  }
  </style>
  